// @import-normalize;
@use '../node_modules/react-toastify/dist/ReactToastify.css';
@use '../node_modules/@chocolate-soup-inc/cs-frontend-components/lib/scss/typography.scss';

@config "./tailwind.config.cjs";

@tailwind components;
@tailwind utilities;

:root {
  // --toastify-color-light: #fff;
  --toastify-color-dark: #191C1C;
  --toastify-color-info: var(--md-sys-color-primary-inverse);
  --toastify-color-success: var(--md-sys-color-primary);
  --toastify-color-warning: var(--md-sys-color-tertiary);
  --toastify-color-error: var(--md-sys-color-error);
  // --toastify-color-transparent: rgba(255, 255, 255, 0.7);

  --toastify-color-success: var(--md-sys-color-primary);
  --toastify-color-warning: var(--md-sys-color-tertiary);
  --toastify-color-error: var(--md-sys-color-error);
}