.addBtn {
  margin-top: 1rem;
}

.tableContainer {
  min-height: 8rem;
}

.tableSeparator {
  margin-bottom: 3rem;
}

.tableTitle {
  padding-left: 1rem;
  font-weight: normal;
}

.topContainer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}