@import '../../../../node_modules/@chocolate-soup-inc/cs-frontend-components/lib/scss/typography.scss';

.subTitle {
  @extend .headline-medium;
}

.emailDomains {
  display: flex;
  flex-direction: column;
}

.topComponent {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 16px;
}

.topTitleContainer {
  flex: 1;
}

.topTitle {
  @extend .title-large;
  margin-right: 12px;
}

.topIcons {
  flex: 2;
  justify-content: flex-end;
}

.topIcons, .topTitleContainer {
  display: flex;
  align-items: center;
  gap: 8px;
}