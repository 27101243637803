@import '../../../../node_modules/@chocolate-soup-inc/cs-frontend-components/lib/scss/typography.scss';

.trailingText {
  @extend .label-large;
  color: var(--md-sys-color-outline);
}

.numberInput {
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
  }
}

.shipmentTableContainer {
  display: flex;
  flex-direction: column;
  border: 1px #bababa solid;
  border-radius: 0.5rem;
  padding: 1rem;

  .topTitle {
    @extend .title-medium;
    margin-right: 12px;
    display: flex;
    justify-content: space-between;
  }
}

.datePicker {
  margin-top: 0.25rem;
}

.shipmentsContainer {
  display: flex;
  margin-top: 1rem;
  flex-direction: column;
  gap: 0.5rem;
}

.tableSpace75px {
  width: 75px !important;
}

.tableSpace90px {
  width: 90px !important;
}

.tableSpace125px {
  width: 125px !important;
}

.containerTablePrintingMultipleLabel {
  margin-top: 1rem;
  padding: 0 !important;
  border: 1px #bababa solid;
  border-radius: 0.5rem;
  * {
    font-size: 0.9rem;
  }
}

.loading {
  height: 30px !important;
  width: 30px !important;
}
