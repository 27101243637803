.recipientCell {
  display: flex;
  align-items: center;
  gap: 8px;
}

.actions {
  position: fixed;
  bottom: 16px;
  right: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: flex-end;
}

.companyName {
  display: flex;
  align-items: center;
  gap: 8px;
}