@import '../../../node_modules/@chocolate-soup-inc/cs-frontend-components/lib/scss/typography.scss';

.miniGiftIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: 1px solid #000;
  color: #000;
  width: 24px;
  height: 24px;
  @extend .label-small;
}