.filters {
  position: sticky;
  z-index: 2;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 0 32px 32px;
  gap: 16px;
}

.filter {
  flex-grow: 1;
  min-width: 11rem;
  max-width: 22rem;
}

.dateField > * {
  display: block !important;
}