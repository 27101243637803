@import '../../../../node_modules/@chocolate-soup-inc/cs-frontend-components/lib/scss/typography.scss';

.movingToDoneModalContent {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 16px;
}

.movingToDoneGroupOuter {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
}

.movingToDoneGroupInner {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 16px;
}

.goingToDoneModal {
  max-height: 90vh !important;
  min-width: 768px;
  max-width: 80vw;
  width: initial !important;
}