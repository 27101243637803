@import '../../../node_modules/@chocolate-soup-inc/cs-frontend-components/lib/scss/typography.scss';

.board {
  width: 100%;
  height: 100%;
  display: grid;
  gap: 18px;
  overflow-x: auto;
  padding: 24px;
  box-sizing: border-box;
  grid-template-rows: 100%;
}

@for $i from 1 through 10 {
  .board.columns#{$i} {
    grid-template-columns: repeat($i, minmax(310px, 1fr));
  }
}

.column {
  border: 1px solid var(--md-sys-color-disabled);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 4px;
  padding: 12px;
  overflow: hidden;
  transition: background-color 0.3s ease;
}

.columnTitle {
  @extend .title-small;
  color: var(--md-sys-color-on-surface);
}

.columnBody {
  flex: 1;
  overflow-y: auto;
  margin: 0 -12px;
  padding: 12px;
  background-color: inherit;
}

.columnBodyInner {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  // gap: 8px;
  width: 100%;
  position: relative;
  background-color: inherit;
}

.column {
  background-color: var(--md-sys-color-background);
}

.column.isDroppable {
  background-color: var(--md-sys-color-surface-variant) !important;
}

.column.isOver.isDroppable {
  background-color: var(--md-sys-color-secondary-container) !important;
}

.draggableCard {
  // display: inline-block;
  position: relative;
  width: 100%;
  background-color: inherit;
}

.draggableCardInner {
  background-color: inherit;
}

.draggableCard + .draggableCard {
  margin-top: 8px;
}

.draggableCard.isDragging {
  opacity: 0.6;
}

.dragOverlay {
  position: relative;
}

.dragOverlayCounter {
  position: absolute;
  right: -9px;
  top: -9px;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  background-color: var(--md-sys-color-surface);
  color: var(--md-sys-color-on-surface);
  box-shadow: var(--md-sys-elevation-level1);

  @extend .body-small;
}