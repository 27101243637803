@import '../../../../node_modules/@chocolate-soup-inc/cs-frontend-components/lib/scss/typography.scss';

.colorIndicatorContainer, .countryContainer {
  display: flex;
  align-items: center;
  gap: 8px;
}

.colorIndicator {
  content: '';
  width: 16px;
  height: 16px;
  border-radius: 50%;
}

.modal {
  max-height: 80vh !important;
}

.giftDetailsContent {
  padding-top: 8px;
  display: grid;
  grid-template-columns: 1fr minmax(200px, 250px);
  gap: 32px;
}

.form {
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  & > div:first-child {
    display: flex;
    flex-direction: column;
    align-items: stretch;
  }
}

.topComponent {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 16px;
}

.topTitleContainer {
  flex: 1;
}

.topTitle {
  @extend .title-large;
  margin-right: 12px;
}

.topIcons {
  flex: 2;
}

.topIcons, .topTitleContainer {
  display: flex;
  align-items: center;
  gap: 8px;
}

.giftSpecialNotes{
  border: solid 1px var(--md-sys-color-surface-variant);
  border-radius: 12px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin-bottom: 24px;

  .giftSpecialNotesTitle{
    font-family: var(--md-sys-typescale-label-large-font);
    font-weight: var(--md-sys-typescale-label-large-weight);
    font-size: var(--md-sys-typescale-label-large-size);
    font-style: var(--md-sys-typescale-label-large-font-style);
    line-height: var(--md-sys-typescale-label-large-line-height);
  }
  .giftSpecialNotesContent{
    font-family: var(--md-sys-typescale-body-medium-font);
    font-weight: var(--md-sys-typescale-body-medium-weight);
    font-size: var(--md-sys-typescale-body-medium-size);
    font-style: var(--md-sys-typescale-body-medium-font-style);
    line-height: var(--md-sys-typescale-body-medium-line-height);
  }
}