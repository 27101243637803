.pending {
  color: var(--md-sys-color-on-disabled);
}

.running {
  color: var(--md-sys-color-secondary);
}

.error {
  color: var(--md-sys-color-error);
}

.success {
  color: var(--md-sys-color-primary);
}

.sm{
  margin: 0;
  max-width: 1.5rem;
  max-height: 1.5rem;
  font-size: 1.5rem;
}

.actionDetailsContainer dt:is(*) {
  grid-column: span 3 / span 3 !important;
}

.actionDetailsContainer dd:is(*) {
  grid-column: span 5 / span 5 !important;
}

pre {
  margin: 0 0 8px;
  padding: 0;
  white-space: pre-wrap;
}

.jsonContainer {
  font-family: monospace, monospace;
  font-size: 0.8rem;
}