.pending {
  color: var(--md-sys-color-on-disabled);
}

.running {
  color: var(--md-sys-color-secondary);
}

.error {
  color: var(--md-sys-color-error);
}

.success {
  color: var(--md-sys-color-primary);
}

.sm{
  margin: 0;
  max-width: 1.5rem;
  max-height: 1.5rem;
  font-size: 1.5rem;
}