@import '../../../../node_modules/@chocolate-soup-inc/cs-frontend-components/lib/scss/typography.scss';

.form {
  padding-top: 12px;
}

.trailingText {
  @extend .label-large;
  color: var(--md-sys-color-outline);
}

.numberInput {
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
  }
}

.containerCards {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  //   justify-content: center;
  gap: 2rem;
}

.rows {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
}

.items {
    flex: 1 1 calc(50% - 8px);
    max-width: calc(50% - 8px);
    margin-bottom: 8px;
}

.loading{
    height: 30px !important;
    width: 30px !important;
}