.alertContainer {
  position: relative;

  .alertDismissButton {
    position: absolute;
    bottom: 1.333rem;
    left: 0.666rem;
    margin: 0;
    padding: 0;
    cursor: pointer;

    &:hover {
      color: red;
    }
  }
}
