.actionIcon {
  margin: 0 8px;
  padding: 4px;
  cursor: pointer;
  border-radius: 50%;
  background-color: #eee;

  &:hover {
    background-color: #dfdfdf;
  }
}