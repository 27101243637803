@import '../../../../node_modules/@chocolate-soup-inc/cs-frontend-components/lib/scss/typography.scss';

.employeeDependants {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.empty {
  @extend .body-medium;
  text-align: center;
}