@import '../../../node_modules/@chocolate-soup-inc/cs-frontend-components/lib/scss/typography.scss';

.uploader {
  background-color: var(--md-sys-color-disabled);
  border: 1px dashed var(--md-sys-color-on-disabled);
  padding: 24px;
  border-radius: 8px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  --state-layer-color: var(--md-sys-color-on-surface);

  p {
    @extend .body-medium;
  }

  &.isDragActive {
    background-color: var(--md-sys-color-secondary-container);
    border-color: var(--md-sys-color-secondary);

    --state-layer-color: var(--md-sys-color-secondary);
  }
}

.uploaderContent {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 16px;
}

.selectedFile {
  display: flex;
  align-items: center;
  gap: 4px;
}