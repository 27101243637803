.rowNameColumn {
  display: flex;
  width: 500px;
  align-items: center;
  gap: 16px;
}

.errorChip {
  --md-sys-color-secondary-container: var(--md-sys-color-error);
  --md-sys-color-on-secondary-container: var(--md-sys-color-on-error);
  --md-sys-color-on-surface: var(--md-sys-color-on-error);
}