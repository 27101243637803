@import '../../../../node_modules/@chocolate-soup-inc/cs-frontend-components/lib/scss/typography.scss';

.card {
  display: block;
  width: 100%;

  & + .card {
    margin-top: 24px;
  }
}

.userOptions {
  position: relative;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

// .userName {
//   padding: 12px;
// }

.name {
  display: block;
  margin-bottom: 2px;
}

.email {
  @extend .body-medium;
  display: block;
  color: var(--md-sys-color-on-surface-variant);
}

.title {
  @extend .title-large;
  margin-bottom: 24px;
}

.addNew {
  display: block;
  margin-bottom: 24px;
}

.usersTable {
  min-height: 250px;
}

.activeUser{
    display: flex;
    justify-content: center;
}