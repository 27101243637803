@import '../../../../node_modules/@chocolate-soup-inc/cs-frontend-components/lib/scss/typography.scss';

.modal {
  max-height: 90vh;
}

.actionSelect {
  margin-bottom: 16px;
}

.text {
  padding: 16px 0;
  @extend .body-medium;
}

.shipmentCardContainer {
  cursor: pointer;
  background: none;
  border: 0;
  padding: 0;
  outline: 0;
  width: 100%;

  & > div {
    width: 100%;
  }

  & + .shipmentCardContainer {
    margin-top: 16px;
  }
}

.cardContent {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-areas:
    "checkbox title"
    "address address";
  gap: 16px;
  align-items: center;
}

.cardCheckbox {
  grid-area: checkbox;
}

.cardTitle {
  @extend .title-large;
  grid-area: title;
  text-align: left;
}

.cardAddress {
  @extend .body-medium;
  grid-area: address;
  text-align: left;
}