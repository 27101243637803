@import '../../../../../node_modules/@chocolate-soup-inc/cs-frontend-components/lib/scss/typography.scss';

.subTitle {
  @extend .headline-medium;
}

.emailDomains {
  display: flex;
  flex-direction: column;
}

.topComponent {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 16px;
}

.topTitleContainer {
  flex: 1;
}

.topTitle {
  @extend .title-large;
  margin-right: 12px;
}

.topIcons {
  flex: 2;
  justify-content: flex-end;
}

.topIcons, .topTitleContainer {
  display: flex;
  align-items: center;
  gap: 8px;
}

.infoCardInnerContainer dt:is(*) {
  grid-column: span 3 / span 3 !important;
}

.infoCardInnerContainer dd:is(*) {
  grid-column: span 5 / span 5 !important;
}

.productsContainer {
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
}

.productDetailsCard {
  width: calc(50% - 1rem) !important;
  box-sizing: border-box !important;
  margin: 1rem 0 0 0 !important;
}

.emptyProductsCard {
  display: flex;
  width: 100% !important;
  box-sizing: border-box !important;
  margin: 1rem 0 0 0 !important;
  justify-content: center;
}

.addBtn {
  margin-top: 1rem;
}
