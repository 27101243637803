@import '../../../../node_modules/@chocolate-soup-inc/cs-frontend-components/lib/scss/typography.scss';

.modal {
  & > div {
    display: flex !important;
    // flex-direction: column !important;
    // overflow: hidden !important;
  }
  max-height: 80vh !important;
  // height: initial !important;
  // overflow: hidden;
}

.employeeModal {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.titleWrapper {
  display: flex;
  align-items: flex-start;
  margin-bottom: 12px;
}

.titleContainer {
  flex: 1;
  margin-top: 6px;
  display: flex;
  align-items: center;
  gap: 16px;
  flex-wrap: wrap;
}

.title {
  @extend .headline-small;
}

.header {
  flex: 0;
  padding-bottom: 12px;
}

.content {
  flex: 1;
  margin: 0 -24px;
  padding: 24px;
  overflow-y: auto;
}

.errorChip {
  --md-sys-color-secondary-container: var(--md-sys-color-error);
  --md-sys-color-on-secondary-container: var(--md-sys-color-on-error);
  --md-sys-color-on-surface: var(--md-sys-color-on-error);
}

.chipsContainer {
  display: flex;
  align-items: center;
  gap: 16px;
  flex-wrap: wrap;
}