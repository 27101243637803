@import '../../../../node_modules/@chocolate-soup-inc/cs-frontend-components/lib/scss/typography.scss';

.countryContainer {
  display: flex;
  align-items: center;
  gap: 8px;
}

.recipient {
  display: flex;
  align-items: center;
  gap: 8px;
}

.topComponent {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 16px;
}

.topTitle {
  flex: 1;
  @extend .title-large;
}

.topIcons {
  display: flex;
  align-items: center;
  gap: 8px;
}

.shipmentDetailsContent {
  padding-top: 8px;
  display: grid;
  grid-template-columns: 1fr minmax(200px, 215px);
  gap: 32px;
}

.form {
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  & > div:first-child {
    display: flex;
    flex-direction: column;
    align-items: stretch;
  }
}

.rightPart {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.addressContainer {
  margin-top: 24px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 24px;

  & > * {
    margin: 0 !important;
  }
}

.addressTopComponent {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 24px;

  h2 {
    @extend .title-medium;
  }
}

.addressLoading {
  width: 46px;
  height: 46px;
}

.addressVerifiedSuccess {
  color: var(--md-sys-color-primary);
}

.addressVerifiedFail {
  color: var(--md-sys-color-error);
}

.addressVerifiedWarning {
  color: var(--md-sys-color-tertiary);
}

.actionDetailsContainer dt:is(*) {
  grid-column: span 3 / span 3 !important;
}

.actionDetailsContainer dd:is(*) {
  grid-column: span 5 / span 5 !important;
}

pre {
  margin: 0 0 8px;
  padding: 0;
  white-space: pre-wrap;
}

.jsonContainer {
  font-family: monospace, monospace;
  font-size: 0.7rem;
}