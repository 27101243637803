.deleted {
  background-color: #ffcccc !important;
}

.badgesContainer {
  display: flex;
  flex-direction: row;
  align-content: center;
  gap: 4px;
}

.warningIcon {
  margin-top: -4px;
  color: red;
}