@import '../../../../node_modules/@chocolate-soup-inc/cs-frontend-components/lib/scss/typography.scss';

.card {
  min-width: 200px;
  width: 100%;
  padding: 16px !important;
  cursor: pointer;
}

.noteIconBorder {
  padding: 0;
  font-size: 1rem !important;
  color: var(--md-sys-color-on-surface);
  border: solid 1px var(--md-sys-color-on-surface);
  border-radius: 4px;
}
.office {
  border: dotted !important;
  border-color: grey !important;
}

.card.loading {
  display: flex;
  align-items: center;
  justify-content: center;
}

.card:not(.loading) {
  display: grid;
  gap: 8px;
  grid-template-columns: min-content 1fr;
  grid-template-areas:
    "companyName companyName icons"
    "eventType recipientName recipientName"
    "eventType relationship relationship"
    "tags tags tags";
}

.icons {
  grid-area: icons;
  display: flex;
  align-items: center;
  gap: 8px;
  margin: -12px -8px;
}

.companyName {
  grid-area: companyName;
  @extend .body-small;
  color: var(--md-sys-color-outline);
}

.recipientName {
  grid-area: recipientName;
  @extend .title-medium;
}

.relationship {
  margin-top: -8px;
  display: flex;
  align-items: center;
  gap: 4px;
  grid-area: relationship;
  @extend .body-small;
  color: var(--md-sys-color-outline);
}

.relationshipIcon {
  font-size: 18px;
  color: var(--md-sys-color-primary);
}

.eventDate {
  display: flex;
  align-items: center;
  gap: 4px;
  grid-area: eventDate;
  @extend .body-medium;
  
  span {
    font-size: 24px;
  }
}

.eventType {
  grid-area: eventType;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 8px;
}

.birthdayEventType, .workAnniversaryEventType, .newHireEventType {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;

  span {
    font-size: 24px;
  }
}

.birthdayEventType {
  background-color: var(--md-sys-color-secondary);
  span {
    margin-top: -3px;
    color: var(--md-sys-color-on-secondary);
  }
}

.workAnniversaryEventType {
  background-color: var(--md-sys-color-tertiary);
  span {
    color: var(--md-sys-color-on-tertiary);
  }
}

.newHireEventType {
  background-color: var(--md-sys-color-primary);
  span {
    color: var(--md-sys-color-on-primary);
  }
}

.tags {
  grid-area: tags;
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 8px;
}

.chip {
  border-color: var(--md-sys-color-disabled) !important;
  padding: 0 4px !important;

  span:last-child {
    @extend .body-small;
    font-weight: 300;
    white-space: wrap;
  }
}

.group {
  padding: 0 8px;
  border-width: 0 1px;
  border-style: dashed;
  border-color: var(--md-sys-color-primary);
  padding-bottom: 6px;
}

.group.groupFirst {
  padding-top: 8px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  border-top-width: 1px;
  margin-top: 8px;
  background-color: inherit;

  &::before {
    content: attr(data-group);
    position: absolute;
    top: 8px;
    left: 15px;
    @extend .body-small;
    color: var(--md-sys-color-primary);
    transform: translateY(-50%);
    padding: 0 12px;
    background-color: inherit
  }
}

.group.groupFiltered {
  &::after {
    content: attr(data-filtered-amount) ' Filtered';
    position: absolute;
    top: 8px;
    right: 15px;
    @extend .body-small;
    color: var(--md-sys-color-primary);
    transform: translateY(-50%);
    padding: 0 12px;
    background-color: inherit
  }
}

.group:not(.groupFirst) {
  margin-top: -6px;
  padding-top: 6px;
}

.group.groupLast {
  border-bottom-width: 1px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  padding-bottom: 8px;
}

.alert {
  --md-sys-color-surface: var(--md-sys-color-tertiary-container);
  --md-sys-color-surface-variant: var(--md-sys-color-container);
}

.errorChip {
  --md-sys-color-secondary-container: var(--md-sys-color-error);
  --md-sys-color-on-secondary-container: var(--md-sys-color-on-error);
  --md-sys-color-on-surface: var(--md-sys-color-on-error);
}