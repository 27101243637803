@import '../../../node_modules/@chocolate-soup-inc/cs-frontend-components/lib/scss/typography.scss';

.bySplitButtonDropdown {
  display: inline-flex;
  position: relative;

  .byActionButton, .byActionButtonRounded, .byToggleButton {
    background-color: var(--md-sys-color-primary);
    color: white !important;
    border: 1px solid #ddd;
    cursor: pointer;

    &:hover, & *:hover {
      box-shadow: none !important;
      background-color: #147870;
    }
  }

  .byToggleButton {
    border-radius: 0 10px 10px 0;
    padding: 0.5rem 1rem;
  }

  .byActionButton {
    border-radius: 10px 0 0 10px !important;
  }

  .byActionButtonRounded {
    border-radius: 10px;
  }

  .byDropdownMenu {
    position: absolute;
    margin-bottom: 5px;
    bottom: 100%;
    border: 1px solid #ddd;
    background-color: white;
    width: 100%;
    z-index: 1000;

    .byDropdownItem {
      padding: 0.5rem 1rem;
      width: 100%;
      text-align: left;
      border: none;
      background-color: white;
      cursor: pointer;

      &:hover {
        background-color: #eee;
      }
    }
  }
}