.filters {
  z-index: 2;
  display: flex;
  align-items: center;
  padding-top: 16px;
  gap: 16px;
}

.filter {
  flex-grow: 1;
  min-width: 49%;
  max-width: 100%;
}