table {
  padding: 0 24px !important;
}

th, td {
  padding: 12px !important;
  white-space: normal !important;
}

.tableSpacePrimary,
.tableSpaceSecondary,
.tableSpaceTertiary,
.tableSpaceQuaternary {
    display: flex; 
    flex-direction: row;
    align-items: center;
}

.tableSpacePrimary {
  min-width: 250px !important;
  max-width: 250px !important;
}

.tableSpaceSecondary {
  min-width: 160px !important;
  max-width: 160px !important;
}

.tableSpaceTertiary {
  min-width: 140px !important;
  max-width: 140px !important;
}

.tableSpaceQuaternary {
  min-width: 100px !important;
  max-width: 100px !important;
}

.tableLoading {
  width: 24px !important;
  height: 24px !important;
}

.header {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 32px;
}

.title {
  margin: 0;
  padding: 0;
}

.contentContainer {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 100%;

  & > *:last-child {
    flex: 1;
  }
}