.noAddressRow {
  background-color: var(--md-sys-color-tertiary-container);
  // &::after {
  //   content: '';
  //   width: 100%;
  //   height: 100%;
  //   position: absolute;
  //   left: 0;
  //   top: 0;
  //   z-index: -1;
  //   border-radius: 100px;
  //   background-color: var(--md-sys-color-tertiary-container);
  // }
}

.missingAddress {
  display: flex;
  align-items: center;
  gap: 8px;
}

.companyName {
  display: flex;
  align-items: center;
  gap: 8px;
}