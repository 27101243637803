@import '../../../../node_modules/@chocolate-soup-inc/cs-frontend-components/lib/scss/typography.scss';

.splittingShipments {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 16px;
}

.droppableShipment {
  padding: 16px;
  background-color: var(--md-sys-color-background);
  border: 1px dashed var(--md-sys-color-on-disabled);
  border-radius: 8px;
  transition: background-color 0.3s ease, border-color 0.3s ease;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 16px;
}

.droppableShipment.isDragging {
  background-color: var(--md-sys-color-surface-variant);
  border-color: var(--md-sys-color-primary);
}

.droppableShipment.isOver {
  background-color: var(--md-sys-color-secondary-container);
  border-color: var(--md-sys-color-containr);
}

.droppableShipmentHeader {
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.droppableShipmentTitle {
  @extend .title-small;
}

.droppableShipmentIcon {
  margin: -12px;
}

.draggableGift.isDragging {
  opacity: 0.5;
}

.addShipmentButton {
  @extend .label-large;
  background-color: var(--md-sys-color-surface-variant);
  color: var(--md-sys-color-on-surface);
  border: 1px dashed var(--md-sys-color-on-disabled);
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  position: relative;
  cursor: pointer;
  min-height: 216px;

  --state-layer-color: var(--md-sys-color-on-surface);
}