@import '../../../node_modules/@chocolate-soup-inc/cs-frontend-components/lib/scss/typography.scss';

.layout {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 256px 1fr;
  grid-template-rows: 40px 1fr;
  grid-template-areas:
    "header header"
    "sidebar content";
}

.header {
  grid-area: header;
}

.sidebar {
  grid-area: sidebar;
  overflow-y: auto;
}

.content {
  grid-area: content;
  overflow-y: auto;
  // padding-bottom: 32px;
}

.contentOutlet {
  @apply col-span-12;
  height: 100%;
}

.titleOutlet {
  @extend .headline-medium;
  padding: 32px;
  margin-bottom: 16px;
}

.fullGrid {
  @apply mx-auto grid grid-cols-4 tablet:grid-cols-12 laptop:grid-cols-12 computer:grid-cols-12 gap-2 tablet:gap-3 laptop:gap-8 computer:gap-8;
  height: 100%;
}

.gridContainer {
  @extend .fullGrid;
  @apply container;
}